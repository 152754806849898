<template>
  <div id="home" class="app">
    <div class="container-fluid px-0 mx-0" style="background-color: #2E2D2D">
           <img src="../assets/images/Aerial_HD 2.png" class="img-fluid" width="100%" alt="">
        </div>
        <div class="container-fluid py-5" id="about" style="background-color: #2E2D2D">
            <div class="container">
                <div class="row pb-3">
                  <div class="col-1"></div>
                    <div class="col-10 textTitle text-start">
                        <h2>ESTUSAE ALAM INDAH</h2>
                        <h4 class="garis mt-0 pt-1"></h4>
                    </div>
                    <div class="col-1"></div>
                </div>
                <div class="row">
                  <div class="col-1"></div>
                    <div class="col-10 text-start textContent">
                        <p> Masterpiece property yang dikembangkan oleh PT Bangun Estusae Propertindo.</p>
                        <p> Berada di epicentrum kawasan strategis Cikopo Purwakarta diatas lahan 24 Hektar terdiri dari 7 tipe rumah cluster serta bangunan komersil lainnya yang dilengkapi dengan berbagai fasilitas premium untuk menunjang kebutuhan penghuni dan warga di sekitarnya.</p>
                        <p> Mendukung mobilitas tinggi para penghuninya karena memiliki akses yang strategis dan terintegrasi (TOD – Transit Oriented Development). </p>
                        <p> ini sangat cocok bagi generasi milenial yang memiliki gaya hidup dinamis demi menghasilkan kualitas hidup yang lebih baik.</p>
                    </div>
                    <div class="col-1"></div>
                </div>
            </div>
        </div>
  </div>
</template>
<script>


export default {
  components: {  },
  name: 'About',
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>


<style scoped>

@font-face {
 
    src: url(../font/F37Bolton-Light.woff2.ttf);
    font-family: F37 Bolton;
}

.garis {
    border-bottom: 2px solid #D68307; 
    width: 50px;
}

.textTitle {
  font-family: Manuale;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.325em;
  color: #FFFFFF;
}
.textContent{
    font-family: F37 Bolton;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.035em;

  color: #FFFFFF;

}
</style>